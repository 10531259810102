import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Carousel,
} from '../../../../components'

const CarouselCsr = ({ data }) => {
  const { t } = useTranslation()
  return (

    <div className="csr__display carousel">
      <Carousel
        data={data}
        sToShow={1}
        sToScroll={1}
        arrows={true}
        dots={true}
        simple={true}
        dotsMobile={true}
        arrowsMobile={true}
        centerModeMobile={false}
        sToShowMobile={1}
        sToScrollMobile={1}
        hasBar={false}
      >
        {data && data.map((insight, i) => <div key={i} className="csr__display--carousel">
          <img src={insight.image.url} />
        </div>)}
      </Carousel>
    </div>

  )
}

export default CarouselCsr
