import React from 'react'
import {
  TitleTextBlock,
} from '../../../../components'

const HeaderCrs = ({
  data,
  t,
  title,
  colorTitle
}) => (
  <TitleTextBlock
    textButton={data.link.title}
    link={data.link.url}
    widthImage={true}
    text={t('tagline.csr')}
    descr={data.copy}>
    {colorTitle ? <div dangerouslySetInnerHTML={{ __html: colorTitle}}/> : title}
  </TitleTextBlock>
)

export default HeaderCrs
