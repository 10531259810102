import _ from 'lodash'
import React from 'react'
import { ButtonCircle } from '../../../../components'
import { useTranslation } from 'react-i18next'

const CsrBody = ({ data }) => {
    const { i18n } = useTranslation();

   return (
    <div className="csrBody__container">
        {_.map(data.list, (item, idx) => (
        (idx % 2 === 0) ? (
            <div className="csrBody__row row" key={item.id}>
            <div className="csrBody__card__image col-12 col-md-7">
                <div className="csrBody__image">
                <img src={_.get(item, 'image.url')} alt={_.get(item, 'image.alt')} />
                </div>
            </div>
            <div className="csrBody__card__content col-12 col-md-5">
                <h3>{_.get(item, 'title')}</h3>
                <p>{_.get(item, 'content')}</p>
                <ButtonCircle
                link={`${i18n.language === 'en' ? '' : `/${i18n.language}`}/csr/${_.get(item, 'slug')}`}
                onClick={() => { }}
                />
            </div>
            </div>
        ) : (
            <div className="csrBody__row row" key={item.id}>
            <div className="csrBody__card__image col-12 order-md-2 col-md-7">
                <div className="csrBody__image">
                <img src={_.get(item, 'image.url')} alt={_.get(item, 'image.alt')} />
                </div>
            </div>
            <div className="csrBody__card__content col-12  order-md-1 col-md-5">
                <h3>{_.get(item, 'title')}</h3>
                <p>{_.get(item, 'content')}</p>
                <ButtonCircle
                link={`${i18n.language === 'en' ? '' : `/${i18n.language}`}/csr/${_.get(item, 'slug')}`}
                onClick={() => { }}
                />
            </div>
            </div>
        )
        ))}
    </div>
    )
}

// eslint-disable-next-line eol-last
export default CsrBody