/* eslint-disable camelcase */
import React from 'react'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import './csr-detail-styles.scss'
import {
  GradientBanner,
  Spinner,
  Tagline,
} from '../../components'
import ImagesDisplay from './components/images-display'
import CsrRelated from './components/csr-articles'

const CrsView = ({
  data,
  loading,
}) => {
  const { t } = useTranslation()
  if (loading) {
    return <Spinner loading={loading} />
  }
  const { acf, related_articles } = data

  return (
    <React.Fragment>

      <div className="crsDetail__container">
        <div className="crsDetail__head">

          <Tagline text={t('tagline.csr')} />
          <div className="largeTitle">
            <h1 className="page-title">{data.title}</h1>
          </div>
        </div>

        <div>
          {acf && _.map(acf.rows, (item) => item.acf_fc_layout !== 'related_articles' && (
            <div className="row crsDetail__row" key={item.title}>
              <div className="col-12 col-md-5 crsDetail__content">
                <h3>{item.title}</h3>
                <div dangerouslySetInnerHTML={{ __html: item.excerpt }} />
              </div>
              <div className="col-12 col-md-7">
                <ImagesDisplay item={item}/>
                <div dangerouslySetInnerHTML={{ __html: item.copy }} />
              </div>
            </div>
          ))}
        </div>
        <div className='industries__content'>
          {related_articles && <CsrRelated data={related_articles} t={t} />}
        </div>

      </div>
      <GradientBanner />
    </React.Fragment>

  )
}

export default CrsView
