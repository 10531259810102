import React, { useEffect, useState } from 'react'
import makeRequest from '../../helpers/make-request'
import CrsView from './csr-detail-view'
import { useTranslation } from 'react-i18next'

const CrsCotroller = ({ slug }) => {
  const [loading, setLoading] = useState(true)
  const [dataCrs, setDataCrs] = useState([])
  const controller = new AbortController()

  const { i18n } = useTranslation();
  // catch with useEffect so the data will be contained
  const getDataCrs = async () => {
    setLoading(true)
    const fields = 'id,title,slug,acf'
    const headers = {
      'Content-Type': 'application/json',
    }

    // fetch the data with makerequest
    makeRequest({
      headers,
      endPoint: 'csr',
      signal: controller.signal,
      params: {
        slug,
        fields,
        lang: i18n.language
      },
    }).then((resp) => {
      setDataCrs(resp.data[0])
      setLoading(false)
    }).catch((error) => {
      console.error(error)
      setLoading(false)
    })
  }
  useEffect(() => {
    getDataCrs()
    return () => {
      controller.abort()
    }
  }, [])

  const viewProps = {
    data: dataCrs,
    loading,
  }
  return (
    <CrsView {...viewProps}/>
  )
}

export default CrsCotroller
